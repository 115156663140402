import { Box, Typography } from '@mui/material';
import * as React from 'react';
import Ads from '../Ads/Ads';
import { TabValueEnum } from '../utils/RouteHelper';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box style={{ margin: '0 auto', height: '100%' }}>
        <div>{children}</div>
        {value === TabValueEnum.RECIPE ? null : <Ads />}
      </Box>
    </Typography>
  );
}
