/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styles from './Ads.module.scss';
import { getCdnUrl } from '../utils/env';

export default function Ads(): JSX.Element {
  const [isClient, setIsClient] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div className={styles['ads-container']}>
      <section className={styles['ads-text']}>
        <h1>Vill ni som företag synas på vår hemsida och nå ut till vår målgrupp?</h1>
      </section>
      <div className={styles['ads-imageBox']}>
        {!isMobile && isClient ? (
          <>
            {/* <div className={styles['ads-image-container']}>
              <img src={getCdnUrl('/images/foraldrar_annons.webp')} alt="parent" className={styles['ads-image']} />
              <button type="button">
                <h2
                  onClick={() => {
                    (window.location as any) = 'mailto:marknadsfora-babyforum@outlook.com?subject=Marknadsföring';
                  }}
                  style={{ cursor: 'pointer', color: '#ffffff', fontWeight: 'bold' }}
                >
                  Kontakta oss
                </h2>
              </button>
                </div> */}
            <div className={styles['ads-image-container']}>
              <img src={getCdnUrl('/images/annonsera_babyforum.webp')} alt="baby" className={styles['ads-image']} />
              <button type="button">
                <h2
                  onClick={() => {
                    (window.location as any) = 'mailto:marknadsfora-babyforum@outlook.com?subject=Marknadsföring';
                  }}
                  style={{ cursor: 'pointer', color: '#ffffff', fontWeight: 'bold' }}
                >
                  Kontakta oss
                </h2>
              </button>
            </div>
          </>
        ) : null}
        <div className={styles['ads-image-container']}>
          <img src={getCdnUrl('/images/annonsera_gravid.webp')} alt="babyad" className={styles['ads-image']} />
          <button type="button">
            <h2
              onClick={() => {
                (window.location as any) = 'mailto:marknadsfora-babyforum@outlook.com?subject=Marknadsföring';
              }}
              style={{ cursor: 'pointer', color: '#ffffff', fontWeight: 'bold' }}
            >
              Kontakta oss
            </h2>
          </button>
        </div>
      </div>
    </div>
  );
}
