const baseUrl = process.env.BASE_URL || 'http://localhost:3000';

const environment = {
  development: 'development'
};

const currentEnv = process.env.APP_ENV || process.env.NODE_ENV || environment.development;

const isDevelopmentEnv = () => currentEnv === environment.development;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const joinUrlSegments = (segments: any) => {
  if (!segments?.length) {
    return '';
  }

  const lastSegmentIndex = segments.length - 1;

  const urlSegments = segments.map((segment: string, index: number) => {
    let urlSegment = index > 0 && segment.startsWith('/') ? segment.slice(1) : segment;
    urlSegment = index < lastSegmentIndex && urlSegment.endsWith('/') ? urlSegment.slice(0, -1) : urlSegment;

    return urlSegment;
  });

  return urlSegments.join('/');
};

const getAbsoluteUrl = (path: string) => {
  if (!path) {
    return baseUrl;
  }

  return joinUrlSegments([baseUrl, path]);
};

const getCdnUrl = (path: string) => {
  const cdnBase = 'https://babyforum.azureedge.net';
  return cdnBase + path;
};

export { isDevelopmentEnv, getAbsoluteUrl, getCdnUrl };
