import * as React from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import Button from '@mui/material/Button';
import styles from './startpage.module.scss';
import rightArrow from '../assets/images/right-arrow.svg';
import ForumList from '../Forum/ForumList';
import { CategoryIdEnum } from '../Models/Category';
import { ApplicationState } from '../Store';
import { LikeActions, LikeState } from '../Store/Like/Like';
import { hasValidToken } from '../Auth/AuthUtils';
import trophy from '../assets/images/trophy-white.svg';
import { IPostFeed } from '../Models/FeedModel';
import { RecipeModel } from '../Models/RecipeModel';

interface IExposedProps {
  parentPosts: IPostFeed[];
  pregnantPosts: IPostFeed[];
  topPosts: IPostFeed[];
  recipePosts: RecipeModel[];
}

interface ReduxProps {
  like: LikeState;
}

type Props = typeof LikeActions & ReduxProps & IExposedProps;

const StartPage = (props: Props): JSX.Element => {
  const { like, getLikesByUserPostId, parentPosts, pregnantPosts, topPosts, recipePosts } = props;
  const { likes } = like;

  const [tokenIsValid, setTokenIsValid] = React.useState<boolean>(false);

  const router = useRouter();

  React.useEffect(() => {
    setTokenIsValid(hasValidToken());
  }, []);

  React.useEffect(() => {
    if (!hasValidToken()) return;
    parentPosts.forEach((v) => {
      getLikesByUserPostId(v.id);
    });
  }, [parentPosts]);

  React.useEffect(() => {
    if (!hasValidToken()) return;
    pregnantPosts.forEach((v) => {
      getLikesByUserPostId(v.id);
    });
  }, [pregnantPosts]);

  React.useEffect(() => {
    if (!hasValidToken()) return;
    topPosts.forEach((v) => {
      getLikesByUserPostId(v.id);
    });
  }, [topPosts]);

  const onLinkClicked = (categoryId: CategoryIdEnum) => {
    const linkTo =
      categoryId === CategoryIdEnum.PARENT
        ? '/foraldrar'
        : categoryId === CategoryIdEnum.PREGNANT
          ? '/gravid'
          : '/barnmat';
    router.push(linkTo);
  };

  const becomeMemberClick = () => {
    router.push('/signin');
  };

  return (
    <>
      {/* <div
        style={{
          backgroundColor: '#000000',
          color: 'white',
          textShadow: '0 0 3px #000',
          padding: 34,
          backgroundImage: `url(${StrollerImage})`,
          objectFit: 'cover',
          backgroundPosition: 'top',
          backgroundSize: 'cover'
        }}
      >
        <div className="header-text">
          <h1 style={{ fontSize: 20, margin: 5 }}>Snart lanseras:</h1>
          <h1 style={{ fontSize: 25, margin: 5 }}>MiniMeetup!</h1>
          <h1 style={{ fontSize: 18, marginTop: 5, marginBottom: 20, textAlign: 'center' }}>
            Lär känna föräldrar nära dig för en fika, träff eller barnvagnspromenad.
          </h1>
        </div>
        <a className="btn-member" href="/minimeetup">
          Läs mer
        </a>
      </div> */}
      {!tokenIsValid ? (
        <div className="new-post-container">
          <div className="header-image">
            <div className="header-text">
              <h1>babyforum.nu - Sveriges främsta plattform & forum för gravida och föräldrar!</h1>
              <h2>
                Skriv och ställ frågor och lär känna andra gravida eller föräldrar, missa inte att få tips och mycket
                mer.
              </h2>
            </div>
            <Button className="btn-member" type="button" onClick={() => becomeMemberClick()}>
              Bli medlem gratis
            </Button>
          </div>
        </div>
      ) : null}

      <section style={isMobile ? { marginLeft: 5, marginRight: 5 } : {}}>
        <div className={styles.startPageContainer}>
          <div className={styles.startPageContainerChild}>
            <div className={styles['trophy-section']}>
              <img alt="trophy" src={trophy} className={styles['trophy-icon']} />
              <span className={styles['txt-top-posts']} style={{ display: 'block' }}>
                TOPPINLÄGG
              </span>
            </div>
            {topPosts.length === 0 && <p>Inga toppinlägg just nu</p>}
            {topPosts.length && <ForumList posts={topPosts} likes={likes} />}
          </div>
          <div className={styles.startPageContainerChild}>
            <div className={styles.startPageHeaderContainer} onClick={() => onLinkClicked(CategoryIdEnum.RECIPE)}>
              BARNMAT
              <img alt="rightarrow" src={rightArrow} className={styles['right-arrow']} />
            </div>
            {recipePosts.length === 0 && <p>Inga recept just nu</p>}
            {recipePosts && <ForumList posts={recipePosts} likes={likes} disablePostActions />}
          </div>
        </div>
        <div className={styles.startPageContainer}>
          <div className={styles.startPageContainerChild}>
            <div className={styles.startPageHeaderContainer} onClick={() => onLinkClicked(CategoryIdEnum.PREGNANT)}>
              GRAVID
              <img alt="rightarrow" src={rightArrow} className={styles['right-arrow']} />
            </div>
            {pregnantPosts.length === 0 && <p>Inga inlägg just nu</p>}
            {pregnantPosts.length && <ForumList posts={pregnantPosts} likes={likes} />}
          </div>
          <div className={styles.startPageContainerChild}>
            <div className={styles.startPageHeaderContainer} onClick={() => onLinkClicked(CategoryIdEnum.PARENT)}>
              FÖRÄLDER
              <img alt="rightarrow" src={rightArrow} className={styles['right-arrow']} />
            </div>
            {parentPosts.length === 0 && <p>Inga inlägg just nu</p>}
            {parentPosts && <ForumList posts={parentPosts} likes={likes} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default connect(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (state: ApplicationState, ownProps: IExposedProps) => {
    return { like: state.like };
  },
  { ...LikeActions }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(StartPage as any);
