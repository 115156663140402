import * as React from 'react';
import SEO from '../SEO/SEO';

import TabPanel from '../Common/TabPanel';
import { CategoryIdEnum } from '../Models/Category';
import { IPostFeed } from '../Models/FeedModel';
import { IPagedPostRequest } from '../Models/RequestModel';
import FeedService from '../Services/Feed/FeedService';
import StartPage from '../StartPage/StartPage';
import { TabValueEnum } from '../utils/RouteHelper';
import RecipeService from '../Services/Recipe/RecipeService';
import { IPagedRecipeResponse, RecipeModel } from '../Models/RecipeModel';

export default function IndexPage(data: {
  pregnantFeed: IPostFeed[];
  parentFeed: IPostFeed[];
  topPosts: IPostFeed[];
  recipeFeed: RecipeModel[];
}) {
  const { parentFeed, pregnantFeed, topPosts, recipeFeed } = data;

  return (
    <>
      <SEO
        title="Bebis forum. Forum för dig som är med barn"
        description="Forum gravid. Forum för föräldrar. Diskutera kring graviditet, föräldraskap, vårdnad, autism och ja i princip allt kring bebisar"
      />
      <TabPanel value={TabValueEnum.STARTPAGE} index={TabValueEnum.STARTPAGE}>
        <StartPage parentPosts={parentFeed} pregnantPosts={pregnantFeed} topPosts={topPosts} recipePosts={recipeFeed} />
      </TabPanel>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStaticProps = async (): Promise<any> => {
  const topPostCount = 9;
  const postCount = 5;
  const recipePostCount = 3;

  const pregnantFeed = await FeedService.getPostFeed({
    categoryId: CategoryIdEnum.PREGNANT,
    skip: 0,
    take: postCount
  } as IPagedPostRequest);

  const parentFeed = await FeedService.getPostFeed({
    categoryId: CategoryIdEnum.PARENT,
    skip: 0,
    take: postCount
  } as IPagedPostRequest);

  const topPosts = await FeedService.getTopPostFeed({ skip: 0, take: topPostCount } as IPagedPostRequest);

  const recipeResponse = await RecipeService.getRecipes({ skip: 0, take: recipePostCount } as IPagedPostRequest);
  const recipeFeed = (await recipeResponse.json()) as IPagedRecipeResponse;

  return {
    props: {
      pregnantFeed,
      parentFeed,
      topPosts,
      recipeFeed: recipeFeed.recipes
    },
    revalidate: 10
  };
};
