import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { IBreadcrumbModel, QAStructuredData } from './BreadcrumbModel';
import { IRecipeStructuredData } from './RecipeStructureModel';

interface Props {
  description: string;
  title: string;
  breadcrumb?: IBreadcrumbModel[];
  qaBreadcrumb?: QAStructuredData;
  recipeBreadcrumb?: IRecipeStructuredData;
  keywords?: string;
  noindex?: boolean;
  imgUrl?: string;
}

interface GoogleBreadcrumb {
  '@context': string;
  '@type': string;
  itemListElement: GoogleBreadcrumbChildren[];
}

interface GoogleBreadcrumbChildren {
  '@type': string;
  position: number;
  name: string;
  item: string;
}

const siteTitle = 'Babyforum';
const siteBase = 'https://babyforum.nu';
const defaultKeywords =
  'mellistips, mellis, mellanmål, enklamaträtter, enklarecept, barnmat, barnvänligarecept, föräldraforum, forum, gravid, mammagrupp, pappagrupp, mötesplats, familjeliv, föräldraliv, vimedbarn, viföräldrar, vigravida småbarnsförälder, babyprodukter, familjemiddag';

export default function SEO(props: Props): JSX.Element {
  const router = useRouter();

  const { description, title, breadcrumb, qaBreadcrumb, keywords, noindex, imgUrl, recipeBreadcrumb } = props;
  const fullUrl = siteBase + router.asPath;

  const breadcrumbs = React.useMemo(() => {
    if (!breadcrumb && !qaBreadcrumb) return null;

    const breadCrumbScripts = [];

    const googleBreadcrumb = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: !breadcrumb
        ? []
        : [
            ...breadcrumb.map((value, index) => {
              if (!value.url) return { '@type': 'ListItem', name: value.name, position: index + 1 };

              return {
                '@type': 'ListItem',
                item: value.url,
                name: value.name,
                position: index + 1
              } as GoogleBreadcrumbChildren;
            })
          ]
    } as GoogleBreadcrumb;

    breadCrumbScripts.push(
      <script key={1} type="application/ld+json">
        {JSON.stringify(googleBreadcrumb)}
      </script>
    );

    if (qaBreadcrumb) {
      breadCrumbScripts.push(
        <script key={2} type="application/ld+json">
          {JSON.stringify(qaBreadcrumb)}
        </script>
      );
    }

    if (recipeBreadcrumb) {
      breadCrumbScripts.push(
        <script key={3} type="application/ld+json">
          {JSON.stringify(recipeBreadcrumb)}
        </script>
      );
    }

    return breadCrumbScripts;
  }, [breadcrumb, qaBreadcrumb]);

  return (
    <Head>
      <title>{`${title} | ${siteTitle}`}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={fullUrl} />
      {imgUrl ? (
        <meta property="og:image" content={imgUrl} />
      ) : (
        <meta property="og:image" content="https://babyforum.azureedge.net/images/babyforum_logo.png" />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="og:keywords" content={keywords || defaultKeywords} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      {noindex && <meta name="robots" content="noindex,nofollow" />}
      <link rel="canonical" href={fullUrl} />
      {breadcrumbs}
    </Head>
  );
}
